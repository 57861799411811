import React from "react";
import css from './regulatoryComplianceDocuments.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import RegulatoryComplianceDocumentsImage from "../../../images/sections/EUDR/Regulatory-Compliance-Documents.svg";
import ListItem from "../../shared/ListItem/ListItem";


const RegulatoryComplianceDocuments = () => {

    const title = 'Regulatory Compliance Documents';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={RegulatoryComplianceDocumentsImage} alt={'Regulatory Compliance Documents'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    A Workflow system is required to collect and process data and to collect and create Due Diligence
                    Statements. Beyond the standard data mandated by the EUDR, the nature of this documentation can vary
                    significantly based on the specific risk context of each country.
                    <br/><br/>
                    <ListItem
                        list={['Forest plot and geo-location data as well as data on harvesting dates.', 'Data on involved companies and forest owners.', 'Data on authorizations and certifications.', 'Transaction delivery note specifications.', 'Other, customisation.']}/>
                    <br/>
                    The data must be verifiable by authorities and suitable for customer audits.
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default RegulatoryComplianceDocuments;
