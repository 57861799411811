import React from "react";
import css from './riskAssessment.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import RiskAssessmentImage from "../../../images/sections/EUDR/Risk-Assessment-Mitigation.svg";
import ListItem from "../../shared/ListItem/ListItem";


const RiskAssessment = () => {

    const title = ' Risk Assessment / Mitigation';

    return (
        <div>
            <div className={css.wrapper}>
                <SectionMobileTitle>{title}</SectionMobileTitle>
                <SectionImageVideoWrapper desktopWidth={'48%'}>
                    <div className={css.imageVideoWrapper}>
                        <div className={css.imageWrapper}>
                            <img src={RiskAssessmentImage} alt={'Risk Assessment'}/>
                        </div>
                    </div>
                </SectionImageVideoWrapper>
                <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                    <SectionDesktopTitle>{title}</SectionDesktopTitle>
                    <div className={css.text}>
                        <div className={css.textBold}>Risk Assessment</div>
                        <br/>
                        Based on the gathered information and documentation at the level of sourcing countries, at the
                        level of the suppliers and considering the very nature of the transaction, the operators shall
                        carry out a risk assessment to establish whether there is a risk that the relevant products
                        intended to be placed on the EU market or exported from it are non-compliant.<br/><br/>
                        <ListItem
                            list={['Evaluate the risk of non-compliant products entering the supply chain.', 'Evaluate the risk of mixing with products of unknown origin or origin in high-risk or standard risk countries.', 'Verify and analyse the information collected in accordance with relevant documentation. ']}/>
                    </div>
                </SectionTextWrapper>
            </div>
        </div>
    )
};

export default RiskAssessment;
