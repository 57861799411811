import React from "react";
import css from './dataProtectionAccessLimitation.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import DataProtectionAccessLimitationImage from "../../../images/sections/EUDR/Data-Protection-Access-Limitation.svg";

const DataProtectionAccessLimitation = () => {

    const title = ' Data Protection Access Limitation';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={DataProtectionAccessLimitationImage} alt={'Data Protection Access Limitation'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    To address the concerns of stakeholders throughout the supply chain, the workflow system must
                    facilitate diligent data processing, including options for selective information sharing.
                    Information should not be broadly disseminated but rather distributed on a strict need-to-know
                    basis.
                    <br/><br/>
                    Ensuring data privacy is paramount and plays a critical role in selecting a suitable platform
                    solution. Nonetheless, all pertinent information required to fulfil due diligence obligations must
                    be readily available and accessible.
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default DataProtectionAccessLimitation;
