import React from "react";
import css from './eudrSection.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import EudrSectionImage from "../../../images/sections/EUDR/Eudr_hero.png";
import ListItem from "../../shared/ListItem/ListItem";

const EudrSection = () => {

    const title = '';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={EudrSectionImage} alt={'EUDR'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Key topics to be addressed:
                    <br/><br/>
                    <div>
                        <ListItem
                            list={['What steps can you take to trace sourced timber to their exact geolocation and receive evidence for the deforestation and compliance status of each of your suppliers in your entire supply chain?', 'How can you improve and ease your due diligence processes and effectively monitor your suppliers to comply with the regulation?',
                                'How can you address and avoid the risks associated with non-compliance with the EUDR?', 'What are the potential benefits and chances of implementing sustainable practices in your business, and how  you communicate these to your stakeholders? '
                            ]}/>
                    </div>
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default EudrSection;
