import React from "react";
import css from './eudrDeploymentGuide.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import EudrDeploymentGuideImage from "../../../images/sections/EUDR/EUDR_Deployment_Guide.png";
import MainButton, {mainButtonArrow} from "../../shared/MainButton/MainButton";
import PdfWhiteIco from "../../../images/icons/document_pdf_white.svg";


const EudrDeploymentGuide = () => {

    const title = 'EUDR Deployment Guide';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={EudrDeploymentGuideImage} alt={'Eudr Deployment Guide'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    How TimberID Can Assist You In Getting Compliant?
                    <br/><br/>
                    What It Takes To Be Ready?
                    <br/><br/>
                    Download <span className={css.textBold}>EUDR deployment guide </span> and learn more.
                    <br/><br/>
                </div>
                <a href={"/files/EUDR_deployment_guideQ2_2024.pdf"}>
                    <MainButton label={"EUDR deployment guide"} arrow={mainButtonArrow.rightDown}
                                leftIcon={PdfWhiteIco}/>
                </a>
            </SectionTextWrapper>
        </div>
    )
};

export default EudrDeploymentGuide;
