import React from "react";
import css from './geoSpatialData.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import GeoSpatialDataImage from "../../../images/sections/EUDR/Geo-spatial-Data.svg";
import ListItem from "../../shared/ListItem/ListItem";

const GeoSpatialData = () => {

    const title = 'Geo-Spatial Data';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={GeoSpatialDataImage} alt={'Geo Spatial Data'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Geolocation plays a crucial role in excluding deforestation, forest degradation, and potential
                    illegal sources. According to the EUDR, the requirement for geo-spatial data stipulates that:
                    <br/><br/>
                    <ListItem
                        list={['forest plots smaller than 4 hectares must have a spot geo-location,', 'while plots exceeding this size need polygon coordinates.']}
                    />
                    <br/>
                    Implementing a digital platform and mobile application will streamline the registration of forest
                    plots and associated information, allowing forest owners to input data directly. This approach
                    enhances the accuracy and efficiency of data collection, aiding compliance with regulatory
                    requirements.
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default GeoSpatialData;
