import React from "react";
import css from './dueDiligenceStatements2.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {useTranslation} from "react-i18next";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_eudr"}}) {
    nodes {
   childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DueDiligenceStatements2 = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div>
            <div className={css.imageWrapper}>
                {renderImageByFileName(imagesFluid, 'due_diligence_statements.png', 'due diligence statements')}
            </div>
            <div className={css.wrapper}>
                <div className={css.textWrapper}>
                    <div className={css.inner}>
                        <div className={css.text}>
                            <div className={css.textBold}>Due-Diligence Statements</div>
                            <br/>
                            The EU Information System "Traces", provided by the EU Commission, will serve as the central
                            system for reporting and documentation. This system mandates that operators and major
                            traders within the EU upload information, integrate reference numbers from existing DDS, and
                            issue new Due Diligence Statements.
                            <br/><br/>
                            Adopting a platform solution will streamline your interaction with the Traces system,
                            simplifying your communication with it. Through an API, the platform will automatically
                            extract and compile all relevant information, significantly reducing your effort in system
                            interaction while also guaranteeing the completeness of all data.
                            <br/><br/>
                            For actors outside the EU, the platform can serve as a comprehensive data traceability hub
                            enabling platform users to fulfil compliance criteria and get market access to the EU.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DueDiligenceStatements2;
