import React from 'react';
import css from './carbon.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_carbon"}}) {
    nodes {
   childImageSharp {
        fluid(maxWidth: 600, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Carbon = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));


    return (
        <div className={css.wrapper}>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'carbonmonitoring.png', 'carbon monitoring')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <div className={css.text}>
                    <div className={css.textBold}>
                        Sustainably managed forests and wood products are key to tackling the climate crisis.
                    </div>
                    <br/>
                    By choosing Timber ID platform you are supporting sustainable and eco-friendly practices in forest
                    management.
                    <br/><br/>
                    <div className={css.textBold}> A better picture of your supply chain will also enable you to better
                        address your own carbon footprint.
                    </div>
                </div>
            </SectionTextWrapper>
        </div>
    );
};

export default Carbon;
