import React from "react";
import css from './provenanceVerification.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ProvenanceVerificationImage from "../../../images/sections/EUDR/Provenance-Verification.svg";

const ProvenanceVerification = () => {

    const title = 'Provenance Verification';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={ProvenanceVerificationImage} alt={'Provenance Verification'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    In low-risk countries, documenting transactions will suffice, but verifying the origin of wood
                    materials becomes obligatory in scenarios where there is a tangible risk of mixing legal and illegal
                    sources. Documentation is always based at the transaction level.
                    <br/><br/>
                    In high-risk environments, implementing additional physical traceability measures (such as Timber
                    ID) is crucial for ensuring certainty and verification.
                    <br/><br/>
                    Automating processes is essential to ensuring the accuracy and verifiability of data flow and
                    effectively managing potential risks.
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default ProvenanceVerification;
