import React from "react";
import css from './informationGathering.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import InformationGatheringImage from "../../../images/sections/EUDR/Information-Gathering.svg";
import ListItem from "../../shared/ListItem/ListItem";

const InformationGathering = () => {

    const title = 'Information Gathering';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={InformationGatheringImage} alt={'Information Gathering'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    The information gathering phase is the most labor-intensive aspect of a due diligence process. When
                    performed manually, it is susceptible to mistakes and human error. This highlights the importance of
                    establishing and maintaining robust digital due diligence systems, along with consistent reporting
                    and record-keeping practices.
                    <br/><br/>
                    Digitizing and integrating supply chain members into a digital workflow is essential for simplifying
                    processes, enhancing efficiency, and minimizing the risk of human error. Adopting a platform
                    solution can also streamline record-keeping and documentation efforts, ensuring compliance with
                    legal documentation requirements more effectively.
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default InformationGathering;
