import React from "react";
import css from './environmental.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import EnvironmentalImage from "../../../images/sections/EUDR/Environmental-Social-Impact.svg";


const Environmental = () => {

    const title = 'Environmental – Social – Governance';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={EnvironmentalImage} alt={'Environmental'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    The EUDR mandates compliance not only with environmental legislation but also with social norms in
                    the country of harvest. Trade relations with the EU significantly influence the ability of third
                    countries to achieve the UN Sustainable Development Goals (SDGs), which carries a responsibility to
                    mitigate any adverse effects stemming from these trade relationships
                    <br/><br/>
                    A platform that facilitates the management of your due diligence information and data will not only
                    help you meet legal requirements but also significantly contribute to achieving your environmental
                    and social objectives.
                    <br/><br/>
                    A well-structured due diligence process, supported by a platform solution, will further enable you
                    to demonstrate a high level of governance, which is crucial for fulfilling reporting obligations
                    under the Corporate Sustainability Reporting Directive (CSRD).
                    <br/><br/>
                    It will also ease up your partners!
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default Environmental;
