import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import DueDiligenceStatements from "../components/sections/DueDiligenceStatements/DueDiligenceStatements";
import InformationGathering from "../components/sections/InformationGathering/InformationGathering";
import GeoSpatialData from "../components/sections/GeoSpatialData/GeoSpatialData";
import RiskAssessment from "../components/sections/RiskAssessment/RiskAssessment";
import RiskMitigation from "../components/sections/RiskMitigation/RiskMitigation";
import DataProtectionAccessLimitation
    from "../components/sections/DataProtectionAccessLimitation/DataProtectionAccessLimitation";
import ProvenanceVerification from "../components/sections/ProvenanceVerification/ProvenanceVerification";
import RegulatoryComplianceDocuments
    from "../components/sections/RegulatoryComplianceDocuments/RegulatoryComplianceDocuments";
import EudrSection from "../components/sections/EudrSection/EudrSection";
import Environmental from "../components/sections/Environmental/Environmental";
import Carbon from "../components/sections/Carbon/Carbon";
import DueDiligenceStatements2 from "../components/sections/DueDiligenceStatements/DueDiligenceStatements2";
import Logo from "../images/sections/EUDR/EUDR_compliant.svg";
import css from './eudr.module.scss'
import EudrDeploymentGuide from "../components/sections/EudrDeploymentGuide/EudrDeploymentGuide";

const EudrPage = () => {
    const pageTitle = getRoutes().Eudr.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>
                <div className={css.logo}><img src={Logo} alt="eudr"/></div>
            </PageTitle>

            <EudrSection/>

            <EudrDeploymentGuide/>

            <DueDiligenceStatements/>

            <DueDiligenceStatements2/>

            <RiskAssessment/>

            <RiskMitigation/>

            <RegulatoryComplianceDocuments/>

            <DataProtectionAccessLimitation/>

            <InformationGathering/>

            <ProvenanceVerification/>

            <GeoSpatialData/>

            <Environmental/>

            <Carbon/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default EudrPage;
