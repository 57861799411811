import React from "react";
import css from './dueDiligenceStatements.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import DueDiligenceStatementsImage from "../../../images/sections/EUDR/Due-Diligence-Statements.svg";
import ListItem from "../../shared/ListItem/ListItem";


const DueDiligenceStatements = () => {

    const title = 'Due-Diligence';

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        <img src={DueDiligenceStatementsImage} alt={'Due-Diligence Statements'}/>
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Prior to placing relevant products on the EU market or exporting them from the EU, operators shall
                    collect information and exercise due diligence regarding all relevant products. The same applies to
                    big traders (non-SME traders).
                    <br/><br/>
                    The basic information requirements are:
                    <br/><br/>
                    <ListItem
                        list={['The collection of information, data, and documents.', 'Transaction specification, species, and volumes.', 'Geo-location coordinates of the timber source. ']}/>
                    <br/>
                    Based on risk assessments, additional verifiable information must be provided to demonstrate
                    effective risk mitigation in both standard and high-risk environments.
                    <br/><br/>
                    All records must be kept for a period of 5 years.
                </div>
            </SectionTextWrapper>
        </div>
    )
};

export default DueDiligenceStatements;
