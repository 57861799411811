import React from "react";
import css from './riskMitigation.module.scss';
import ListItem from "../../shared/ListItem/ListItem";
import {graphql, useStaticQuery} from "gatsby";
import {useTranslation} from "react-i18next";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_eudr"}}) {
    nodes {
   childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const RiskMitigation = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));


    return (
        <div>
            <div className={css.imageWrapper}>
                {renderImageByFileName(imagesFluid, 'eudr_compliant.png', 'eudr compliant')}
            </div>
            <div className={css.wrapper}>
                <div className={css.textWrapper}>
                    <div className={css.inner}>
                        <div className={css.text}>
                            <div className={css.textBold}>Risk Mitigation</div>
                            <br/>
                            Before introducing the relevant products to the EU market or exporting them from the EU, the
                            operator must implement risk mitigation procedures and specific measures that effectively
                            address and reduce the risk to a level that is either non-existent or negligible.
                            <br/><br/>
                            Effective mitigation measures could be:
                            <br/><br/>
                            <ListItem
                                list={['Collecting additional information, data, or documents from/on suppliers. ', 'Carrying out independent surveys or audits.', 'Verify the transaction and the origin by measures such as physical traceability.']}/>
                            <br/>
                            Operators must establish policies, controls, and procedures that are both adequate and
                            proportionate to mitigate and effectively manage identified risks of non-compliance for the
                            relevant product. These could encompass:
                            <br/><br/>
                            <ListItem
                                list={['State of the art risk management, reporting, record-keeping, internal controls, and compliance management.', 'An independent audit function to review the effectiveness of internal policies, controls, and procedures.']}/>
                            <br/>
                            The risk assessment and risk mitigation procedures and measures must be thoroughly
                            documented, reviewed at least annually, and provided to the competent authorities upon
                            request, as well as made accessible to customers further down the supply chain.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RiskMitigation;
